import common from './common'

export class Agenzia {

    static LIST = 'azienda/view'
    static UPDATE = 'azienda/update/'
    static SERVICES = 'azienda/sottoservizi/list'
    static TEXTURES = 'websites/texture/list'
    static TEMPLATES = 'websites/template/list'

    static get() {
        return axios.get(common.baseUrl() + this.LIST);
    }

    static update(id, data) {
        return axios.patch(common.baseUrl() + this.UPDATE + id, data);
    }

    static sottoservizi() {
        return axios.get(common.baseUrl() + this.SERVICES);
    }

    static templates() {
        return axios.get(common.baseUrl() + this.TEMPLATES);
    }

    static texture() {
        return axios.get(common.baseUrl() + this.TEXTURES);
    }

}
